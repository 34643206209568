import eachElement from 'Utils/eachElement.js';

function getCurrentScrollPercentage() {
	return Math.round((document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100);
}

function trackAnalyticsEvent(category, action, label) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'eventTracking',
		eventCategory: category,
		eventAction: action,
		eventLabel: label,
		eventScrollPosition: getCurrentScrollPercentage(),
	});
}

function getLabel(target) {
	const attributeValue = target.closest('[analytics-track-event]').getAttribute('analytics-track-event');
	return attributeValue === ''
		? target.textContent
		: attributeValue;
}

function trackCtaClick(event) {
	if (event && 'target' in event && event.target.nodeType) {
		trackAnalyticsEvent('cta', 'click', getLabel(event.target));
	}
}

function trackCtaContextmenu(event) {
	if (event && 'target' in event && event.target.nodeType) {
		trackAnalyticsEvent('cta', 'click', `${getLabel(event.target)}-right-click`);
	}
}

function trackTaggedAnchors() {
	eachElement('[analytics-track-event]', element => {
		element.addEventListener('click', trackCtaClick);
		element.addEventListener('contextmenu', trackCtaContextmenu);
	});
}

export default {
	trackTaggedAnchors,
	trackAnalyticsEvent,
};
